import classNames from 'classnames';
import { endsWith, isEmpty, isString, omit, values } from 'lodash';
import { useMemo, useState } from 'react';
import { MdArrowBack } from 'react-icons/md';

import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { IoMdCloseCircle } from 'react-icons/io';
import ShadowSelect from '../../app/component/ShadowSelect';
import useAppConfig from '../../app/use-app-configs';
import usePersistAppConfig from '../../app/use-persist-app-state';
import { useModalManage } from '../../common/hooks/use-modal-manage';
import Textarea from '../../components/form/Textarea';
import Modal from '../../components/Modal';
import AquaticsSelector from '../../components/selector/AquaticsSelector';
import {
  Enum_Post_Category,
  PostInput,
  useCreatePostMutation,
  useUpdatePostMutation,
  useUploadMutation,
} from '../../generated/graphql';
import { TPost } from '../../types/gql-enhanced-types';
import { notEmpty, notNull } from '../../utils/array';
import { getMediaUrl } from '../../utils/image';
import useAuth from '../auth/use-auth';
import PostImageSelector from './PostImageSelector';

function ModalPostForm({
  post,
  category: initCategory,
  onClose,
  onSuccess,
}: {
  post?: TPost;
  category?: Enum_Post_Category;
  onClose: () => void;
  onSuccess?: () => void;
}) {
  const [{ isSuperUserOrShadow, isSuperAdmin, user, isPartner1 }] = useAuth();
  const creatorId = user?.id;
  const isEdit = !!post?.id;

  const [{ boardConfigs }] = useAppConfig();
  const defaultBoardConfig = boardConfigs.find((b) => b.id === 'main');
  const [{ shadowNames }, { removeShadowName, addShadowName }] =
    usePersistAppConfig();

  useModalManage({
    modalKey: 'post-create',
    onBlocked: () => {
      onClose();
    },
  });

  const postData = useMemo(() => {
    if (!post)
      return {
        title: '',
        category: initCategory || '',
        tags: '',
        desc: '',
        images: [],
        previews: [],
        // ...(isPartner1
        //   ? {
        //       partnerPostCat: 'notice',
        //     }
        //   : {}),
      };

    const imagesIds = post?.images?.data.map((d) => d.id).filter(notNull) || [];
    const imagesData = post?.images?.data || [];
    const previews = imagesData
      .map((d) => getMediaUrl(d.attributes?.url))
      .filter(notNull);

    return {
      id: post?.id,
      title: post?.title || '',
      category: post?.category || initCategory || '',
      tags: post?.tags || '',
      desc: post?.desc || '',
      images: imagesIds,
      aquatic: post?.aquatic?.data?.id,
      previews,
    };
  }, [post]);

  const { handleSubmit, getValues, setValue, register, watch } = useForm<{
    id?: null | string | undefined;
    title: string | null | undefined;
    category: string | null | undefined;
    desc: string | null | undefined;
    tags: string | null | undefined;
    shadower?: string | null | undefined;
    aquatic?: string | null | undefined;
    images: (File | string)[];
    previews: string[];
    partnerPostCat?: string | null | undefined;
  }>({
    defaultValues: postData,
  });

  const aquatic = watch('aquatic');
  const category = watch('category');
  const images = watch('images');
  const previews = watch('previews');
  const shadower = watch('shadower');

  const boardConfig =
    boardConfigs.find((b) => b.id === category) || defaultBoardConfig;
  const hasTags = notEmpty(boardConfig?.tags);

  const [saving, setSaving] = useState(false);
  const [upload] = useUploadMutation({
    onError() {
      setSaving(false);
    },
  });

  const [createPost] = useCreatePostMutation({
    refetchQueries: ['Posts'],
    onCompleted: () => {
      toast.success('등록완료');
      setSaving(false);

      if (onSuccess) {
        onSuccess?.();
      } else {
        onClose();
      }
    },
    onError(e) {
      toast.error(e.message);
      setSaving(false);
    },
  });

  const [updatePost] = useUpdatePostMutation({
    refetchQueries: ['Posts'],
    onCompleted: () => {
      toast.success('등록완료');
      setSaving(false);

      if (onSuccess) {
        onSuccess?.();
      } else {
        onClose();
      }
    },
    onError() {
      setSaving(false);
    },
  });

  async function handleSave() {
    if (saving) return;

    const nData = getValues();

    if (!hasTags || isEmpty(nData.tags) || nData.tags === '-') {
      nData.tags = null;
    }

    // if (!firstFile) {
    //   toast.warn('이미지는 필수에요.');
    //   return;
    // }

    setSaving(true);

    const uploadIdsOrFiles = await Promise.all(
      images.map(async (f) => {
        if (isString(f)) return f;

        try {
          const { data: uploadData } = await upload({
            variables: {
              file: f,
            },
            // onError(e) {
            //   toast.error(e.message);
            // },
          });

          return uploadData?.upload.data?.id as string;
        } catch (error) {
          // TODO handle better
          // eslint-disable-next-line
          console.error('error', error);
        }

        return f;
      }),
    );

    setValue('images', uploadIdsOrFiles);

    const hasUploadFailedFiles = uploadIdsOrFiles.some(
      (id) => isString(id) === false,
    );

    if (hasUploadFailedFiles) {
      toast.error('이미지 업로드에 실패했습니다. 나중에 다시 시도해주세요.');

      setSaving(false);
      return;
    }

    const uploadIds = uploadIdsOrFiles.filter(isString);
    const data = {
      ...(omit(nData, ['id', 'previews']) as PostInput),
      images: uploadIds,
      creator: creatorId,
    };

    if (!isSuperAdmin) {
      delete data.shadower;
    } else if (data.shadower != null) {
      addShadowName(data.shadower);
    }

    // updating
    if (nData.id) {
      await updatePost({
        variables: {
          id: nData.id,
          data,
        },
      });
      return;
    }

    // CREATING
    await createPost({
      variables: {
        data,
      },
    });
  }

  return (
    <Modal open>
      <div className='flex py-2 flex-center-y'>
        <div className='z-40 flex-none w-0 overflow-visible'>
          <button
            className='btn btn-ghost'
            onClick={() => {
              const yes = window.confirm(
                '작성중인 내용이 사라집니다. 나가시겠습니까?',
              );
              if (!yes) return;

              onClose();
            }}
          >
            <MdArrowBack size={32} />
          </button>
        </div>

        <div className='flex-1 text-center text-modal-title'>
          {boardConfig?.name || '글'}
        </div>

        <div className='z-40 flex-none w-0 overflow-visible'>
          <div className='relative bg-red-300 h-[40px]'>
            <div className='absolute right-0 -top-1'>
              <button
                className='flex flex-col btn btn-ghost whitespace-nowrap btn-lg'
                onClick={() => {
                  handleSubmit(handleSave, (e) => {
                    values(e).forEach((msg) => {
                      toast.error(`${msg.message}`);
                    });
                  })();
                }}
              >
                {saving ? (
                  <span className='loading loading-spinner'></span>
                ) : !isEdit ? (
                  '등록'
                ) : (
                  '수정'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='flex-col flex-1 px-4 overflow-auto gap-9'>
        {isSuperUserOrShadow && (
          <div className='flex-col gap-2'>
            <label htmlFor=''>현재유저: {user?.displayName}</label>
          </div>
        )}
        {isSuperAdmin && (
          <>
            <div className='flex-col gap-2'>
              <label htmlFor=''>작성자</label>

              <ShadowSelect
                value={shadower ? { value: shadower, label: shadower } : null}
                onChange={(o) => {
                  setValue('shadower', o?.value);
                }}
              />
              <div className='flex gap-2 flex-wrap'>
                {shadowNames?.filter(notEmpty)?.map((name, idx) => {
                  return (
                    <span
                      key={`${name}-${idx}`}
                      className='badge badge-primary badge-lg whitespace-nowrap cursor-pointer'
                      onClick={() => {
                        setValue('shadower', name);
                      }}
                    >
                      {name}
                      <span
                        onClick={() => {
                          removeShadowName(name);
                        }}
                      >
                        <IoMdCloseCircle size={20} className='opacity-50' />
                      </span>
                    </span>
                  );
                })}
              </div>
            </div>
            <div className='flex-col gap-2'>
              <label htmlFor=''>
                게시판 <span className='text-red-600'>*</span>
              </label>
              <select
                className='select'
                maxLength={50}
                placeholder='게시판...'
                {...register('category', {
                  required: {
                    value: true,
                    message: '필수입니다.',
                  },
                })}
              >
                <option value={undefined}>-</option>
                {boardConfigs
                  .filter((c) => c.type === 'board')
                  ?.map((bConfig) => {
                    if (['all'].includes(bConfig.id)) return null;

                    return (
                      <option key={bConfig.id} value={bConfig.id}>
                        {bConfig.name}
                      </option>
                    );
                  })}
              </select>
            </div>

            {boardConfig?.id === 'opinion' && (
              <div className='flex-col gap-2'>
                <label htmlFor=''>생울 선택</label>
                <AquaticsSelector
                  value={aquatic ? { value: aquatic, label: aquatic } : null}
                  onChange={(o) => {
                    setValue('aquatic', o?.value);
                  }}
                />
              </div>
            )}
          </>
        )}
        <div className={classNames('flex-col gap-2', !hasTags && 'hidden')}>
          <label htmlFor=''>
            주제 {hasTags && <span className='text-red-600'>*</span>}
          </label>
          <select
            className='select'
            maxLength={50}
            placeholder='주제...'
            {...register(
              'tags',
              hasTags
                ? {
                    required: {
                      value: true,
                      message: '주제 필수입니다.',
                    },
                  }
                : {
                    required: false,
                  },
            )}
          >
            <option value={''}>-</option>
            {boardConfig?.tags?.map((tag) => {
              return (
                <option key={tag} value={tag}>
                  {tag}
                </option>
              );
            })}
          </select>
        </div>
        {isPartner1 && (
          <div className='flex-col gap-3'>
            <label htmlFor='p-notice'>
              공지/프로모션 <span className='text-red-600'>*</span>
            </label>
            <label htmlFor='p-notice' className='opacity-70 text-sm'>
              <span>
                공지/프로모션글은 최신 글 3개만 랜덤하게 글 목록에서 사용자에게
                노출됩니다. 글 작성 후에는 수정될 수 없습니다. 필요한 경우
                재작성 해주세요.
              </span>
            </label>
            <input
              type='checkbox'
              id='p-notice'
              className='toggle toggle-sm'
              checked={watch('partnerPostCat') === 'notice'}
              onChange={(e) => {
                setValue('partnerPostCat', e.target.checked ? 'notice' : null);
              }}
            />
          </div>
        )}

        <div className='flex-col gap-3'>
          <label htmlFor=''>
            제목 <span className='text-red-600'>*</span>
          </label>
          <input
            type='text'
            className='input'
            maxLength={50}
            placeholder='제목...'
            {...register('title', {
              required: {
                value: true,
                message: '제목은 필수입니다.',
              },
            })}
          />
        </div>

        <div className='flex-col gap-3'>
          <label htmlFor=''>
            내용 <span className='text-red-600'>*</span>
          </label>
          <Textarea
            className='textarea-bordered min-h-[7rem]'
            placeholder='예쁜고 착한 글 적어주세요. 커뮤니티 가이드라인 위반시 계정이 차단될 수 있습니다...'
            {...register('desc', {
              required: {
                value: true,
                message: '내용은 필수입니다.',
              },
            })}
          />
        </div>

        <div className='py-3'>
          <div className='w-full flex gap-2 flex-wrap'>
            {previews.map((preview, idx) => {
              return (
                <div key={`post-image-${idx}`} className='relative max-w-[30%]'>
                  <div className='absolute w-0 h-0 overflow-visible -top-3 right-4 z-10'>
                    <span
                      className='block w-8 h-8 bg-white rounded-full'
                      onClick={() => {
                        // remove item at idx
                        setValue(
                          'images',
                          images.filter((_, i) => i !== idx),
                        );
                        setValue(
                          'previews',
                          previews.filter((_, i) => i !== idx),
                        );
                      }}
                    >
                      <IoMdCloseCircle size={32} />
                    </span>
                  </div>
                  <img src={preview} />
                </div>
              );
            })}
          </div>
          <div>
            <PostImageSelector
              selectedFiles={images}
              onFilesSelected={(nFiles) => {
                const files = [...images, ...nFiles].filter((f) => {
                  if (
                    !isString(f) &&
                    (endsWith(f.name, 'gif') || endsWith(f.name, 'svg'))
                  ) {
                    return false;
                  }

                  return true;
                });

                const nPrs = files.map((f) => {
                  if (isString(f)) return f;

                  return URL.createObjectURL(f);
                });

                setValue('images', files);
                setValue('previews', nPrs);
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalPostForm;
