import { memo, useState } from 'react';

import { isEmpty, size } from 'lodash';
import { Waypoint } from 'react-waypoint';
import appConfig from '../../app/appConfig';
import LoadingIcon from '../../components/loading/LoadingIcon';
import { useCommentsQuery } from '../../generated/graphql';
import { convToRender } from '../../types/gql-enhanced-types';
import useBlockState from '../report/hooks/use-block-state';
import Comment from './Comment';
import { getTopCommentQueryParams } from './comment-params';

const { pageSize } = appConfig.comment;

function Comments({
  targetId,
  isTargetOwnerViewing,
}: {
  isTargetOwnerViewing?: boolean;
  targetId: string;
}) {
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [, { filterBlockedComment }] = useBlockState();

  const { data, loading, fetchMore } = useCommentsQuery({
    fetchPolicy: 'cache-and-network',
    variables: getTopCommentQueryParams({
      targetId,
    }),
    onCompleted: () => {
      setLoadingMore(false);
    },
  });

  const countToShow = page * pageSize;
  const comments = data?.comments?.data;
  const commentsToShow = data?.comments?.data.slice(0, countToShow);

  const hasMore = size(commentsToShow) < size(comments);

  if (loading) {
    return (
      <div className='flex-center p-5'>
        <LoadingIcon />
      </div>
    );
  }

  if (isEmpty(commentsToShow)) {
    return null;
  }

  return (
    <div className='flex flex-col gap-[24px]'>
      {commentsToShow?.map((comment) => (
        <Comment
          targetId={targetId}
          key={comment.id}
          comment={comment}
          isTargetOwnerViewing={isTargetOwnerViewing}
          blocked={!filterBlockedComment(convToRender(comment))}
        />
      ))}
      {loadingMore && (
        <div className='flex-center-x p-10'>
          <LoadingIcon />
        </div>
      )}
      {hasMore && (
        <Waypoint
          scrollableAncestor='window'
          onEnter={async () => {
            if (!loading || !loadingMore) {
              setLoadingMore(true);

              const nPage = page + 1;

              await fetchMore({
                variables: {
                  pagination: {
                    page: nPage,
                    pageSize: pageSize + 1,
                  },
                },
              });

              setLoadingMore(false);
              setPage(nPage);
            }
          }}
        />
      )}
    </div>
  );
}

export default memo(Comments);
