import produce from 'immer';
import { isEmpty, size } from 'lodash';
import { memo, useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';
import appConfig from '../../app/appConfig';
import Loading from '../../components/loading/Loading';

import { usePostsNoticeQuery } from '../../generated/graphql';
import { persistAtom } from '../../libs/recoil';
import { convList } from '../../types/gql-enhanced-types';
import PostListItem from './PostListItem';

const NoticeRotateState = atom<{
  lastFetchedMs?: number;
  currRandomSample?: number | null;
}>({
  key: 'NoticeRotateState',
  default: {
    lastFetchedMs: 0,
    currRandomSample: null,
  },
  effects: [persistAtom],
});

function PostNoticeRotate({ showAll }: { showAll?: boolean }) {
  // fetching notice posts
  const { data: noticeData, loading: loadingN } = usePostsNoticeQuery({
    // skip: !allPosts,
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    variables: {
      filters: {
        category: {
          eq: 'notice',
        },
        activeNoti: {
          eq: true,
        },
      },
      pagination: {
        limit: 8,
      },
      sort: ['createdAt:desc'],
    },
  });

  const notices = convList(noticeData?.posts?.data || []);
  const noticeCount = size(notices);
  const loading = loadingN && isEmpty(notices);

  const [noticeRotateState, setNoticeRotateState] =
    useRecoilState(NoticeRotateState);

  const { currRandomSample } = noticeRotateState;

  function resetRandomSample(itemCount: number) {
    setNoticeRotateState((st) =>
      produce(st, (dr) => {
        const nValue = dr.currRandomSample || 0;
        dr.currRandomSample = itemCount ? (nValue + 1) % itemCount : 1;
      }),
    );
  }

  useEffect(() => {
    if (noticeCount) {
      resetRandomSample(noticeCount);

      const timer = setInterval(() => {
        resetRandomSample(noticeCount);
      }, appConfig.post.ppInterval);
      return () => {
        return clearInterval(timer);
      };
    }

    return () => {};
  }, [noticeCount]);

  const post = notices?.[currRandomSample || 0];
  if (!post) return null;

  if (loading) {
    return (
      <div key='post-noti-loading' className='flex-c p-6 bg-blue-50'>
        <Loading />
      </div>
    );
  }

  if (showAll) {
    return (
      <>
        {notices.map((notice, idx) => (
          <PostListItem
            key={`noti-post-${notice.id}-${idx}`}
            isNotice
            post={notice}
          />
        ))}
      </>
    );
  }

  return <PostListItem key={`noti-post-${post.id}`} isNotice post={post} />;
}

export default memo(PostNoticeRotate);
