import 'react-spring-bottom-sheet/dist/style.css';
import appleDownload from '../../assets/banners/app-store.png';
import androidDownload from '../../assets/banners/google-play.png';
import envs from '../../common/envs';

function DownloadButtons() {
  return (
    <div className='flex-col flex-1 gap-6'>
      <div className='flex gap-3'>
        <div
          className='flex-1 cursor-pointer  flex justify-end'
          onClick={() => {
            window.open(envs.mobileAppleLink, '_blank');
          }}
        >
          <div className='max-w-[22rem]'>
            <img src={appleDownload} />
          </div>
        </div>
        <div
          className='flex-1 cursor-pointer flex'
          onClick={() => {
            window.open(
              `https://play.google.com/store/apps/details?id=com.aquadesk.ttopet`,
              '_blank',
            );
          }}
        >
          <div className='max-w-[22rem]'>
            <img src={androidDownload} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownloadButtons;
