import { useApolloClient } from '@apollo/client';
import classNames from 'classnames';
import { first, isEmpty, size } from 'lodash';
import { memo, useEffect } from 'react';
import toast from 'react-hot-toast';
import { MdArrowBack } from 'react-icons/md';
import { Waypoint } from 'react-waypoint';
import LoadingIcon from '../../components/loading/LoadingIcon';
import SkelPostList from '../../components/skeleton/SkelPostList';
import {
  NotiFragment,
  NotiFragmentDoc,
  useNotisV2Query,
  useSetNotisMarkMutation,
  useSetNotisSeenMutation,
} from '../../generated/graphql';
import { usePageLoadMore } from '../../graphql/use-page-load-more';
import useAuth from '../../modules/auth/use-auth';
import StickyHeader from '../../modules/post/StickyHeader';
import { convList } from '../../types/gql-enhanced-types';
import { notNull } from '../../utils/array';
import appConfig from '../appConfig';
import NotiItem from './components/NotiItem';

const { pageSize } = appConfig.notis;

function NotisPage() {
  const [{ userId }, { nav }] = useAuth();
  const { cache } = useApolloClient();

  const {
    data: dataV2,
    loading,
    fetchMore,
  } = useNotisV2Query({
    fetchPolicy: 'cache-and-network',
    skip: !userId,

    variables: {
      filters: {
        to: {
          id: {
            eq: userId!,
          },
        },
        comment: {
          id: {
            notNull: true,
          },
          deleted: {
            eq: false,
          },
        },
      },
      sort: ['createdAt:DESC', 'id:DESC'],
      pagination: {
        page: 1,
        pageSize,
      },
    },
  });

  const dataNotis: NotiFragment[] = dataV2?.notisV2?.notis?.data || [];

  const data = dataNotis
    .map((d) => {
      const notiFrag = cache.readFragment<NotiFragment>({
        id: `NotiEntity:${d.id}`,
        returnPartialData: true,
        fragment: NotiFragmentDoc,
        fragmentName: 'Noti',
      });
      return notiFrag;
    })
    .filter(notNull);

  const items = convList(data).filter((n) => notNull(n.comment?.data));

  const [{ hasMore, loadingMore }, { loadMore }] = usePageLoadMore({
    fetchMore,
    loading,
    pageSize,
    listLength: size(items),
  });

  const [setNotiSeen] = useSetNotisSeenMutation({
    fetchPolicy: 'network-only',
  });
  const [setNotiMark, { loading: marking }] = useSetNotisMarkMutation({
    fetchPolicy: 'network-only',
  });

  const unseenIds = items.filter((n) => !n.seen).map((n) => n.id);
  const unreadIds = items.filter((n) => !n.read).map((n) => n.id);
  const latestItem = first(items);

  useEffect(() => {
    if (!userId) return;
    if (isEmpty(unseenIds)) return;

    setNotiSeen({
      variables: {
        input: {
          date: latestItem?.createdAt || new Date().toISOString(),
          user: userId,
        },
      },
    });
  }, [userId, `${unseenIds}`]);

  return (
    <div className='flex-1 flex-col bg-base-100 pb-4'>
      <div className='flex-1 flex-col overflow-hidden'>
        <StickyHeader>
          <div
            className={classNames(
              'navbar border-b bg-base-100 max-w-2xl',
              'flex justify-between w-full p-0 border-b gap-2',
            )}
          >
            <div className=''>
              <button
                className='btn btn-ghost'
                onClick={() => {
                  nav(-1);
                }}
              >
                <MdArrowBack size={30} />
              </button>
            </div>
            <div className='flex-1 flex-center'>
              <h3 className='text-2xl font-bold text-center'>알림 목록</h3>
            </div>
            <div>
              <button
                className={classNames(
                  'link no-underline text-primary mr-2',
                  isEmpty(unreadIds) && 'opacity-60',
                )}
                onClick={() => {
                  if (marking || isEmpty(unreadIds)) return;
                  if (!userId) {
                    toast.error('로그인이 필요합니다.');
                    return;
                  }

                  setNotiMark({
                    variables: {
                      input: {
                        date: latestItem?.createdAt || new Date().toISOString(),
                        user: userId,
                      },
                    },
                  });
                }}
              >
                모두 읽음
              </button>
            </div>
          </div>
        </StickyHeader>

        <div className='flex-col flex-1 overflow-y-auto pb-32'>
          {isEmpty(items) && loading && <SkelPostList />}
          {isEmpty(items) && !loading && (
            <div className='flex-1 flex-center'>알림이 없습니다.</div>
          )}

          <ul className='flex-col gap-2 overflow-x-hidden px-4 py-4 flex-1'>
            {items?.map((noti) => {
              return <NotiItem key={noti?.id} noti={noti} />;
            })}

            {loadingMore && (
              <div className='flex-center-x p-10'>
                <LoadingIcon />
              </div>
            )}

            {hasMore && (
              <Waypoint
                scrollableAncestor='window'
                onEnter={() => {
                  if (!loadingMore) {
                    loadMore();
                  }
                }}
              ></Waypoint>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default memo(NotisPage);
