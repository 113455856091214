import produce from 'immer';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import { atom, useRecoilState } from 'recoil';
import { persistAtom } from '../../libs/recoil';
import DownloadButtons from './DownloadButtons';

const UserOnboardState = atom<{
  notifyDownload: boolean;
}>({
  key: 'UserOnboardState',
  default: {
    notifyDownload: false,
  },
  effects: [persistAtom],
});

function DownloadInitialPopup() {
  const [state, setState] = useRecoilState(UserOnboardState);

  return (
    <BottomSheet
      className='fixed z-20'
      open={!state.notifyDownload}
      onDismiss={() => {
        setState((st) =>
          produce(st, (dr) => {
            dr.notifyDownload = true;
          }),
        );
      }}
    >
      <div className='p-5'>
        <div className='flex flex-col gap-8'>
          <div className='text-lg font-semibold text-center'>
            또펫 앱도 있어요!
          </div>
          <DownloadButtons />
        </div>
      </div>
    </BottomSheet>
  );
}

export default DownloadInitialPopup;
