import { memo, useState } from 'react';

import { FiEye, FiMessageCircle } from 'react-icons/fi';
import Card from '../../components/Card';
import { FormatsEnum } from '../../types';
import { dateDistance } from '../../utils/date';
import { getSizedMediaLink } from '../../utils/image';
import useAuth from '../auth/use-auth';

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { IoMdHeart, IoMdHeartEmpty } from 'react-icons/io';
import { useAppRouter } from '../../common/hooks/use-app-router';
import { convToRender, TPost } from '../../types/gql-enhanced-types';
import { formatNumber } from '../../utils/number';
import { usePartnerPosts } from './hooks/use-partner-post-state';
import ModalPostForm from './ModalPostForm';
import PostItemMenu from './PostItemMenu';
import { usePostLike } from './use-post-like';

function PartnerPostListItem({ numberFromTop }: { numberFromTop: number }) {
  const [, { nav }] = useAuth();

  const { getPostPath } = useAppRouter();

  // get random
  const { ppPost: post } = usePartnerPosts({
    numberFromTop,
  });

  const postPath = getPostPath(post?.id);

  const [openAddPost, setOpenAddPost] = useState<TPost | null>(null);
  const [{ liked }] = usePostLike({
    post,
  });
  const aquatic = convToRender(post?.aquatic?.data);

  const images =
    [...(post?.images?.data || []), ...(aquatic?.images?.data || [])] || [];
  const items = images.map((upload) => {
    return {
      fullscreen:
        getSizedMediaLink({
          upload,
          size: FormatsEnum.large,
        }) || '',
      small:
        getSizedMediaLink({
          upload,
          size: FormatsEnum.thumbnail,
        }) || '',
    };
  });

  const firstImg = items?.[0];

  if (!post) {
    return null;
  }

  return (
    <Card className='border-b rounded-none bg-[#fffafa]'>
      {openAddPost && (
        <ModalPostForm
          post={openAddPost}
          onClose={() => {
            setOpenAddPost(null);
          }}
        />
      )}
      <div className='flex gap-2'>
        {firstImg && (
          <div
            className='cursor-pointer avatar'
            onClick={() => {
              nav(postPath);
            }}
          >
            {liked && (
              <div className='w-0 h-0'>
                <div className='absolute top-[2.6rem] right-[0.2rem]'>
                  <IoMdHeart className='text-red-600 opacity-100' size={21} />
                </div>
              </div>
            )}
            <div className='w-16 h-16 rounded-xl bg-base-300'>
              {firstImg && <img alt='thumbnail' src={firstImg.small} />}
            </div>
          </div>
        )}

        <div
          className='flex-col flex-1 overflow-x-hidden cursor-pointer'
          onClick={() => {
            nav(postPath);
          }}
        >
          <div className='flex-1 min-w-0 gap-1 overflow-hidden flex-center-y'>
            {aquatic && (
              <span className='badge bg-primary bg-opacity-10'>생물</span>
            )}
            <span key={'광고'} className='badge bg-primary bg-opacity-10'>
              {'광고'}
            </span>
            <div className='flex-1 overflow-hidden text-base text-ellipsis whitespace-nowrap'>
              {post.title}
            </div>
          </div>
          <div className='flex flex-wrap flex-1 gap-x-2'>
            <div className='flex flex-wrap flex-1 basis-2/3 gap-x-3'>
              <div className='text-sm font-bold flex-center-y opacity-40 whitespace-nowrap'>
                {dateDistance(post.createdAt)}
              </div>

              <div className='flex-1 overflow-x-hidden flex-cy gap-1'>
                <div className='flex-c px-[2px] bg-green-200 rounded font-bold opacity-50 text-xs'>
                  P
                </div>
                <div className='flex-1 overflow-hidden whitespace-nowrap text-ellipsis'>
                  {post.creator?.data?.attributes?.displayName || '-'}
                </div>
              </div>
            </div>
            <div className='flex justify-end flex-grow gap-2'>
              {/* partner */}
              {/* <div className='flex-c'>
                <div className='w-1 h-1 bg-red-300 rounded-full'></div>
              </div> */}
              <div className='gap-1 flex-center'>
                <FiMessageCircle className='opacity-50' />
                <span>{formatNumber(post.commentCount || 0)}</span>
              </div>

              <div className='gap-1 flex-center'>
                <FiEye className='opacity-50' />
                <span>{formatNumber(post.viewCount || 0)}</span>
              </div>

              <div className='gap-1 flex-center'>
                {!liked ? (
                  <IoMdHeartEmpty className='opacity-40' />
                ) : (
                  <IoMdHeart className='opacity-60' />
                )}
                <span>{formatNumber(post.likeCount || 0)}</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <PostItemMenu post={post} />
        </div>
      </div>
    </Card>
  );
}

export default memo(PartnerPostListItem);
