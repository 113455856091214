import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { memo } from 'react';
import { MdChevronLeft } from 'react-icons/md';
import { PiSunHorizonBold } from 'react-icons/pi';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import Card from '../../components/Card';
import DeletedPage from '../../components/error/DeletedPage';
import SkelPostView from '../../components/skeleton/SkelPostView';
import { H3 } from '../../components/typographic/Headers';
import { usePostQuery } from '../../generated/graphql';
import { convToRender } from '../../types/gql-enhanced-types';
import useAuth from '../auth/use-auth';
import { getTargetId } from './comment-params';
import CommentForm from './CommentForm';
import Comments from './Comments';
import PostCard from './PostCard';
import PostItemMenu from './PostItemMenu';
import StickyHeader from './StickyHeader';

function PostViewPage() {
  const nav = useNavigate();
  const { postId: paramPostId } = useParams();
  const [sParams] = useSearchParams();
  const [{ userId }] = useAuth();
  const navToComments = sParams.has('comm');

  const variables = {
    id: paramPostId || '',
  };

  const { data, error, loading } = usePostQuery({
    skip: !paramPostId,
    variables,
    onCompleted: () => {
      if (navToComments) {
        setTimeout(() => {
          document.getElementById('comments')?.scrollIntoView();
        }, 200);
      }
    },
  });

  const post = convToRender(data?.post?.data);
  const isPostOwner = post?.creator?.data?.id === userId;

  if (post?.deleted === true) {
    return <DeletedPage />;
  }

  // if (!loading) {
  //   return <SkelPostView />;
  // }

  if (!error && (!post || loading)) {
    return <SkelPostView />;
  }

  if (isEmpty(post)) {
    return (
      <div className='flex-col h-screen gap-4 flex-center'>
        포스트가 없거나 삭제되었습니다.
        <div className='opacity-50 blink-s1'>
          <PiSunHorizonBold size={53} />
        </div>
      </div>
    );
  }

  return (
    <div
      className='flex flex-col'
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
    >
      <StickyHeader>
        <div
          className={classNames(
            'navbar border-b bg-base-100 max-w-2xl',
            'flex justify-between w-full p-0 border-b gap-2',
          )}
        >
          <span
            className='px-2 cursor-pointer'
            onClick={() => {
              nav(-1);
            }}
          >
            <MdChevronLeft className='' size={24} />
          </span>
          <H3
            className='flex flex-1 gap-3 flex-center-y '
            onClick={() => {
              nav(-1);
            }}
          >
            {post?.title}
          </H3>
          <div className='flex-none px-2'>
            <PostItemMenu post={post} />
          </div>
        </div>
      </StickyHeader>

      <div className='flex flex-col gap-[20px]'>
        <PostCard post={post} />

        {/* comments */}

        <Card id='comments' className='gap-8 pr-0 overflow-hidden lg:pr-0'>
          <h3 className='font-bold text-2xl pr-[15px] lg:pr-[30px]'>댓글</h3>
          <CommentForm
            targetId={getTargetId({ post, target: 'post' })}
            defaultPrivate={post?.category === 'trade'}
          />
          {/* comment list */}
          <Comments
            isTargetOwnerViewing={isPostOwner}
            targetId={getTargetId({ post, target: 'post' })}
          />
        </Card>

        <div className='h-20'></div>
      </div>
    </div>
  );
}

export default memo(PostViewPage);
