import classNames from 'classnames';
import produce from 'immer';
import { endsWith, omit, trim } from 'lodash';
import { memo, useCallback, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { FaCheckCircle } from 'react-icons/fa';
import { useDebounce } from 'use-debounce';
import appConfig from '../../app/appConfig';

import Card from '../../components/Card';
import ImgAvatar from '../../components/ImgAvatar';
import LoadingIcon from '../../components/loading/LoadingIcon';
import {
  UserFragment,
  UserFragmentDoc,
  UsersPermissionsUserInput,
  useUpdateMeMutation,
  useUploadMutation,
  useUsernameAvailableLazyQuery,
} from '../../generated/graphql';
import { getErrorMsg } from '../../graphql/utils/error';
import { sleep } from '../../utils/flow';
import useAuth from '../auth/use-auth';
import useBlockState from '../report/hooks/use-block-state';
import {
  notiFields,
  sanitizeFields,
  userCommonFields,
  userConfig,
} from './config';
import { consolidateUser } from './type';

type Inputs = Partial<UsersPermissionsUserInput>;

function ProfileEditPage() {
  const [{ user }, { handleLogin }] = useAuth({
    navigateToUnAuthed: '/',
  });
  const [, { setBlockState }] = useBlockState();

  const [updateUser, { loading, error: mutateError }] = useUpdateMeMutation({
    onCompleted: (d) => {
      handleLogin({
        user: d.updateMe,
      });
    },
    update(cache, { data: mutData }) {
      const frag = cache.readFragment<UserFragment>({
        id: `UsersPermissionsUserEntity:${mutData?.updateMe.id}`,
        fragment: UserFragmentDoc,
        fragmentName: 'User',
      });

      const newFrag = produce(frag, (draft) => {
        if (draft?.attributes) {
          draft.attributes.profileUrl = mutData?.updateMe.profileUrl;
          draft.attributes.displayName = mutData?.updateMe.displayName;
          draft.attributes.username = mutData?.updateMe.username || '';
        }
      });

      cache.writeFragment({
        data: newFrag,

        id: `UsersPermissionsUserEntity:${mutData?.updateMe.id}`,
        fragment: UserFragmentDoc,
        fragmentName: 'User',
      });
    },
  });
  const [updateNoti] = useUpdateMeMutation();

  const [checkUsername, { loading: usernameValidating }] =
    useUsernameAvailableLazyQuery({
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
      onCompleted: (data) => {
        // eslint-disable-next-line
        updateUsernameValidity(
          data?.usernameAvailable.username || '',
          data?.usernameAvailable.available || false,
        );
      },
    });

  const {
    register,
    setValue,
    handleSubmit,
    // reset,
    watch,
    formState: { errors: formErrors },
  } = useForm<Partial<Inputs>>({
    defaultValues: {
      ...omit(sanitizeFields(consolidateUser(user), userCommonFields)!, [
        'id',
        'adminRole',
      ]),
    },
  });

  const {
    register: registerNotiField,
    getValues: getNotiValues,
    watch: watchNotiField,
  } = useForm<Inputs>({
    defaultValues: {
      ...omit(sanitizeFields(consolidateUser(user), notiFields)!, [
        'id',
        'adminRole',
      ]),
    },
  });

  const [uploading, setUploading] = useState(false);
  const [upload] = useUploadMutation({
    onError() {
      toast.error('이미지 업로드 실패');
      setUploading(false);
    },
  });

  const onDrop = useCallback(
    async (files: File[]) => {
      if (uploading) return;

      const firstFile = files?.[0];

      if (firstFile) {
        if (endsWith(firstFile.name, 'gif')) {
          return;
        }

        setUploading(true);

        const uploadR = await upload({
          variables: {
            file: firstFile,
          },
        });

        // https://stackoverflow.com/questions/66759993/google-cloud-storage-not-strongly-consistent-returning-404-until-500ms-after-a
        await sleep(2);
        setUploading(false);

        const thumbUrl =
          uploadR.data?.upload.data?.attributes?.formats?.thumbnail?.url;

        const newUrl = thumbUrl || uploadR.data?.upload.data?.attributes?.url;

        if (newUrl) {
          setValue('profileUrl', newUrl, {
            shouldValidate: true,
          });

          document.getElementById('save-button')?.click();
        }
      }
    },
    [loading, setValue],
  );

  const bio = watch('bio');
  const profileUrl = watch('profileUrl');
  const username = watch('username');
  const notiEmail = watchNotiField('notiEmail');

  const [usernameToValidate] = useDebounce(username, 400);
  const [isUsernameValid, setIsUsernameValid] = useState(true);

  const updateUsernameValidity = useCallback(
    (validatedName: string, valid: boolean) => {
      if (username === validatedName) {
        setIsUsernameValid(valid);
      }
    },
    [username, setIsUsernameValid],
  );

  const usernameTyping = username !== usernameToValidate;

  useEffect(() => {
    const readyToValidate = usernameToValidate === username;

    async function tryValidate() {
      if (!usernameTyping && usernameToValidate && readyToValidate) {
        const unValidR = await checkUsername({
          variables: {
            id: user!.id,
            username: usernameToValidate,
          },
          fetchPolicy: 'network-only',
        });

        // success
        const avaialble = unValidR.data?.usernameAvailable.available || false;

        updateUsernameValidity(usernameToValidate, avaialble);
      }
    }

    if (readyToValidate) {
      tryValidate();
    }
  }, [usernameTyping, username, usernameToValidate]);

  const [success, setSuccess] = useState(false);

  const onSubmit: SubmitHandler<Inputs> = async (values) => {
    if (usernameTyping || usernameValidating || !isUsernameValid || loading) {
      return;
    }

    const createR = await updateUser({
      variables: {
        data: values,
        id: user!.id,
      },
    });

    const newId = createR.data?.updateMe?.id;

    if (newId) {
      const me = createR.data?.updateMe;

      handleLogin({
        user: me,
      });

      // success
      setSuccess(true);
      toast.success('저장 완료');

      setTimeout(() => {
        setSuccess(false);
      }, 1000);
    }
  };

  if (!user) {
    return null;
  }

  const errorMsg = getErrorMsg(mutateError);

  return (
    <div className='flex flex-col gap-[20px]'>
      <Card className='gap-3'>
        <form
          className='flex flex-col gap-[20px]'
          onSubmit={handleSubmit(onSubmit)}
        >
          <h3 className='font-bold text-2xl'>프로필 수정</h3>
          <div className='flex gap-10 lg:flex-row flex-col-reverse'>
            <div className='flex flex-col flex-auto gap-3'>
              <div className='form-control'>
                <label className='label'>
                  <span className='label-text'>이메일</span>
                </label>
                <input
                  type='text'
                  placeholder=''
                  className='input input-bordered pointer-events-none bg-[#efefef] font-bold'
                  readOnly
                  {...register('email', {
                    required: true,
                  })}
                />
              </div>
              <div className='form-control'>
                <label className='label'>
                  <span className='label-text'>이름</span>
                </label>
                <input
                  type='text'
                  placeholder=''
                  className={classNames('input input-bordered', {
                    'border-error': formErrors.displayName,
                  })}
                  {...register('displayName', {
                    required: {
                      value: true,
                      message: 'required',
                    },
                    min: 1,
                    max: 30,
                  })}
                  maxLength={appConfig.user.displayNameMaxLen}
                />
              </div>
              <div className='hidden form-control'>
                <label className='label'>
                  <span className='label-text'>태그명</span>
                </label>

                <span className='relative items-center lg:flex'>
                  <span className='absolute px-2 py-[0.29rem] opacity-60'>
                    @
                  </span>
                  <input
                    type='text'
                    placeholder=''
                    className={classNames(
                      'pl-[1.4rem] input input-bordered w-full',
                      {
                        'border-error pr-[5.7rem]':
                          !isUsernameValid || formErrors.username,
                      },
                    )}
                    {...register('username', {
                      required: {
                        value: true,
                        message: 'required',
                      },
                      min: 1,
                      max: 20,
                      pattern: {
                        value: /^[a-zA-Z0-9._-]+$/i,
                        message: 'number, alphabet, dash, underscore and dot',
                      },
                      onChange: (e) => {
                        const value = trim(e.target.value);
                        const value2 = value.replace(/[^a-zA-Z0-9._-]/g, '');

                        setValue('username', value2);
                      },
                    })}
                    maxLength={appConfig.user.handleMaxLen}
                  />
                  {!isUsernameValid && (
                    <span className='text-error absolute p-2 right-0'>
                      unavailable
                    </span>
                  )}
                </span>
              </div>
            </div>
            <div className='flex-none flex flex-col gap-1'>
              <label className='label justify-center hidden lg:block text-center'>
                <span className='label-text'>프로필 사진 수정</span>
              </label>

              {uploading ? (
                <div className={classNames('flex-center pt-4')}>
                  <div className='w-32 h-32 flex-center bg-gray-100 rounded-full'>
                    <LoadingIcon />
                  </div>
                </div>
              ) : (
                <Dropzone
                  accept={{
                    'image/*': [],
                  }}
                  multiple={false}
                  onDrop={onDrop}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      className={classNames('flex-center pt-4')}
                      {...getRootProps()}
                    >
                      <ImgAvatar
                        className='w-32 h-32 cursor-pointer'
                        key={profileUrl}
                        img={profileUrl}
                      />
                      <input {...getInputProps()} />
                    </div>
                  )}
                </Dropzone>
              )}
            </div>
          </div>
          <div className='form-control'>
            <label className='label'>
              <span className='label-text'>바이오</span>
            </label>
            <textarea
              className='textarea textarea-bordered'
              maxLength={userConfig.bioMax}
              {...register('bio', {
                maxLength: userConfig.bioMax,
              })}
            />
            <div className='text-right py-1 opacity-50 text-xs'>
              {bio?.length || 0} / {userConfig.bioMax}
            </div>
          </div>

          {errorMsg && <div className='text-error'>{errorMsg}</div>}

          <div>
            {success ? (
              <button
                type='button'
                className={classNames('btn btn-white btn-md gap-2', {
                  loading,
                })}
              >
                <FaCheckCircle />
                <span>저장 완료</span>
              </button>
            ) : (
              <button
                id='save-button'
                type='submit'
                className={classNames('btn btn-primary btn-md', {
                  loading,
                })}
              >
                저장
              </button>
            )}
          </div>

          <div className='py-3 flex-col gap-2'>
            <label htmlFor='' className='text-error'>
              실험적 세팅
            </label>
            <div className='flex gap-3'>
              <button
                className='btn'
                onClick={() => {
                  setBlockState((st) =>
                    produce(st, (dr) => {
                      dr.blockedPostIds = [];
                      dr.blockedUserIds = [];
                    }),
                  );
                }}
              >
                차단 리셋
              </button>
            </div>
          </div>
        </form>
      </Card>
      {/* Email Notifications */}
      <Card className='flex-a gap-10 hidden'>
        <form
          className='flex-col flex gap-10'
          onChange={() => {
            updateNoti({
              variables: {
                data: getNotiValues(),
                id: user!.id,
              },
            });
          }}
        >
          <h3 className='font-bold text-2xl flex-center-y justify-between'>
            <label htmlFor='email-noti' className='cursor-pointer'>
              이메일 설정
            </label>
            <input
              id='email-noti'
              type='checkbox'
              className='toggle toggle-primary toggle-sm'
              {...registerNotiField('notiEmail')}
            />
          </h3>
          <div className='flex flex-col gap-4'>
            <h5 className='font-bold flex-center-y justify-between'>
              <span>Frequency</span>
            </h5>
            <h6 className='flex-center-y justify-between'>
              <label
                className={classNames('cursor-pointer', {
                  'opacity-50': !notiEmail,
                })}
                htmlFor='frequency-daily'
              >
                Daily
              </label>
              <input
                {...registerNotiField('notiFrequency')}
                type='radio'
                id='frequency-daily'
                className='radio radio-primary radio-xs'
                disabled={!notiEmail}
                value='daily'
              />
            </h6>
            <h6 className='flex-center-y justify-between'>
              <label
                className={classNames('cursor-pointer', {
                  'opacity-50': !notiEmail,
                })}
                htmlFor='frequency-weekly'
              >
                Weekly
              </label>
              <input
                {...registerNotiField('notiFrequency')}
                type='radio'
                id='frequency-weekly'
                className='radio radio-primary radio-xs'
                disabled={!notiEmail}
                value='weekly'
              />
            </h6>
            {
              // NOTE:  disabling due to the complexity to inplement 2 times a week
              // <h6 className='flex-center-y justify-between'>
              //   <label
              //     className={classNames('cursor-pointer', {
              //       'opacity-50': !notiEmail,
              //     })}
              //     htmlFor='frequency-bi-weekly'
              //   >
              //     Bi-weekly
              //   </label>
              //   <input
              //     className='radio radio-primary radio-xs'
              //     {...registerNotiField('notiFrequency')}
              //     disabled={!notiEmail}
              //     type='radio'
              //     id='frequency-bi-weekly'
              //     value='bi_weekly'
              //   />
              // </h6>
            }
          </div>
          <div className='flex flex-col gap-4'>
            <h5 className='font-bold flex-center-y justify-between'>Send me</h5>
            <h6 className='flex-center-y justify-between'>
              <label
                className={classNames('cursor-pointer', {
                  'opacity-50': !notiEmail,
                })}
                htmlFor='send-me'
              >
                Newsletter with trending posts
              </label>
              <input
                {...registerNotiField('notiPostsOfFollowing')}
                type='checkbox'
                id='send-me'
                className='checkbox checkbox-primary checkbox-xs'
                disabled={!notiEmail}
              />
            </h6>
          </div>
          <div className='flex flex-col gap-4'>
            <h5 className='font-bold flex-center-y justify-between'>
              Notify me when
            </h5>
            <h6 className='flex-center-y justify-between'>
              <label
                htmlFor='follows-me'
                className={classNames('cursor-pointer', {
                  'opacity-50': !notiEmail,
                })}
              >
                Someone follows me
              </label>
              <input
                {...registerNotiField('notiOnFollowMe')}
                id='follows-me'
                type='checkbox'
                className='checkbox checkbox-primary checkbox-xs'
                disabled={!notiEmail}
              />
            </h6>
            <h6 className='flex-center-y justify-between'>
              <label
                htmlFor='likes-post'
                className={classNames('cursor-pointer', {
                  'opacity-50': !notiEmail,
                })}
              >
                Someone likes my post
              </label>
              <input
                {...registerNotiField('notiOnLikes')}
                id='likes-post'
                type='checkbox'
                className='checkbox checkbox-primary checkbox-xs'
                disabled={!notiEmail}
              />
            </h6>
            <h6 className='flex-center-y justify-between'>
              <label
                htmlFor='comments'
                className={classNames('cursor-pointer', {
                  'opacity-50': !notiEmail,
                })}
              >
                Someone comments on my post
              </label>
              <input
                {...registerNotiField('notiOnComments')}
                id='comments'
                type='checkbox'
                className='checkbox checkbox-primary checkbox-xs'
                disabled={!notiEmail}
              />
            </h6>
          </div>
        </form>
      </Card>
      <div className='h-24'></div>
    </div>
  );
}

export default memo(ProfileEditPage);
