import classNames from 'classnames';
import { endsWith, max, size } from 'lodash';
import { useCallback } from 'react';
import Dropzone from 'react-dropzone';
import { FaPlus } from 'react-icons/fa';
import appConfig from '../../app/appConfig';

const { imageMaxCount } = appConfig.post;

function PostImageSelector({
  onFilesSelected,
  selectedFiles,
}: {
  onFilesSelected: (f: File[]) => void;
  selectedFiles: (File | string)[];
}) {
  const selectedCount = size(selectedFiles);
  const imageLeftCount = max([imageMaxCount - selectedCount, 0]) || 0;
  const canUploadMore = imageLeftCount > 0;

  const onDrop = useCallback(
    async (files: File[]) => {
      const nFiles =
        files.filter((f) => {
          return !endsWith(f.name, 'gif');
        }) || [];
      onFilesSelected(nFiles.slice(0, imageLeftCount));
    },
    [onFilesSelected],
  );

  return (
    <Dropzone
      accept={{
        'image/*': [],
      }}
      multiple={imageMaxCount > 1}
      onDrop={onDrop}
      disabled={!canUploadMore}
    >
      {({ getRootProps, getInputProps }) => (
        <div className={classNames('pt-4')} {...getRootProps()}>
          <div className='flex-col gap-1'>
            <button className='gap-2 btn btn-outline' disabled={!canUploadMore}>
              <FaPlus size={14} /> <span>{'이미지'}</span>
            </button>

            <div className='text-center opacity-50'>
              <span>* {`${imageMaxCount}`}장 까지 업로드 가능</span>
            </div>
          </div>
          <input {...getInputProps()} />
        </div>
      )}
    </Dropzone>
  );
}

export default PostImageSelector;
