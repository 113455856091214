import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import { useModalManage } from '../../common/hooks/use-modal-manage';
import useGlobalState from '../report/hooks/use-global-state';
import DownloadButtons from './DownloadButtons';

function DownloadSheet() {
  const [, { setDownloadApp, setReportState }] = useGlobalState();

  useModalManage({
    modalKey: 'download-app-sheet',
    onBlocked: () => {
      setReportState(null);
    },
  });

  return (
    <BottomSheet
      className='fixed z-20'
      open
      onDismiss={() => {
        setDownloadApp(false);
      }}
    >
      <div className='p-5'>
        <div className='flex flex-col gap-8'>
          <div className='text-lg font-semibold text-center'>앱 다운로드</div>
          <DownloadButtons />
        </div>
      </div>
    </BottomSheet>
  );
}

export default DownloadSheet;
